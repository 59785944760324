import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { TechnicalUserEntry, UserEntry } from '../_model/user';
import { NGXLogger } from 'ngx-logger';
import { TrainingEntry, DailyEntry } from '../_model/entries';

/**
 * Diese Klasse simuliert ein Backend auf Basis eines HTTP Interceptors.
 */
@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  /**
   * die ID für die Trainings
   */
  private static TRAINING_ID = "training_be"
  /**
   * die ID für den täglichen Trainingseintrag
   */
  private static DAILY_ID = "daily_be";
  /**
   * die ID im Session Storage
   */
  private static USER_ID = "users_be";
  /**
   * die ID im Session Storage
   */
  private static CURRENT_USER_ID = "current_user_be";
  /**
   * die ID für die Trainingslast und -dauer Reports für Kategorien
   */
  private static REPORT_LOAD_CATEGORY_ID = "training_load_category_be";
  /**
   * die ID für die Trainingslist und -dauer nach Wochen
   */
  private static REPORT_LOAD_WEEK_ID = "training_load_week_be";
   /**
   * die ID für die Trainingslist und -dauer nach Wochen
   */
  private static REPORT_LOAD_WEEK_DETAILS_ID = "training_load_week_details_be";
  /**
   * die ID für die Trainingslast und -dauer Reports für Methoden
   */
  private static REPORT_LOAD_METHOD_ID = "training_load_method_be";
  /**
   * die ID für die Basisdaten
   */
  private static REPORT_BASICS = "training_basics_be";

  /**
   * der momentane Benutzer
   */
  private currentUser: TechnicalUserEntry;
  /**
   * der Name des ID Token headers
   */
  public static ID_TOKEN_HEADER = "X-Authorization";

  constructor(private http: HttpClient, private log: NGXLogger) { }

  /**
   * Diese Funktion lädt den Inhalt einer Datei in lokale Variablen, damit diese retourniert werden können.
   * @param filename der Name der Datei
   * @param name der eindeutige Name im Local Storage
   */
  private getDataFromFileOrVariable(filename: string, name: string): Observable<HttpResponse<any>> {
    if (!sessionStorage.getItem(name)) {
      // hole die Daten aus der JSON Datei
      return this.http.get(filename).pipe(
        tap((output) => {
          // speichere sie in der lokalen Variable
          this.log.debug("Hole Daten aus Datei \"" + filename + "\"");
          // speichere die Variable in der Session
          sessionStorage.setItem(name, JSON.stringify(output));
        }),
        // bastle wieder eine HTTP response (Observeable) für die Rückgabe
        switchMap((output) => {
          return of(new HttpResponse({ status: 200, body: output }));
        })
      );
    }
    else {
      // verwende direkt die Variable
      return of(new HttpResponse({ status: 200, body: JSON.parse(sessionStorage.getItem(name)) }));
    }
  }

  /**
   * Die Hauptfunktion, welche die Anfragen abhandelt.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(() => {
      if (request.headers.has(FakeBackendInterceptor.ID_TOKEN_HEADER) &&
        request.headers.get(FakeBackendInterceptor.ID_TOKEN_HEADER) != "none#") {
        this.log.debug("ID Token Header: " + request.headers.get(FakeBackendInterceptor.ID_TOKEN_HEADER));
      }
      else {
        this.log.warn("Kein Token gefunden bei Aufruf mit URL \"" + request.url + "\"");
        //return of (new HttpResponse({ status: 401, statusText: 'Unauthorized' }));
      }
      this.log.debug("Verarbeite Request mit URL \"" + request.url + "\" :" + request.method);
      /* --------------------------------------------------
       *      Benutzer Funktionen
       * --------------------------------------------------*/
      // Liste der Benutzer retournieren
      if (request.url.endsWith("/users") && request.method === 'GET') {
        this.log.info("Hole Benutzer einer Gruppe");
        return this.getDataFromFileOrVariable("assets/test/users.json", FakeBackendInterceptor.USER_ID);
      }
      // Authorisierung
      if (request.url.endsWith('/users/authenticate') && request.method === 'GET') {
        this.log.info("Authorisiere Benutzer");
        return this.getDataFromFileOrVariable("assets/test/current-user.json", FakeBackendInterceptor.CURRENT_USER_ID);
      }
      // Benutzer speichern
      if (request.url.endsWith('/users') && request.method === 'POST') {
        // find if any user matches login credentials
        let newUser: UserEntry = request.body;
        newUser.id = newUser.name;
        this.log.info("Speichere Benutzer: " + JSON.stringify(newUser));
        let users: UserEntry[] = JSON.parse(sessionStorage.getItem(FakeBackendInterceptor.USER_ID));
        users.push(newUser);
        sessionStorage.setItem(FakeBackendInterceptor.USER_ID, JSON.stringify(users));
        return of(new HttpResponse({ status: 200 }));
      }
      // Benutzer aktualisieren
      if (request.url.endsWith('/users') && request.method === 'PUT') {
        // find if any user matches login credentials
        let existingUser: UserEntry = request.body;
        this.log.info("Aktualisiere Benutzer: " + JSON.stringify(existingUser));
        let users: UserEntry[] = JSON.parse(sessionStorage.getItem(FakeBackendInterceptor.USER_ID));
        for (let i: number = 0; i < users.length; i++) {
          if (users[i].id === existingUser.id) {
            users[i] = existingUser;
          }
        }
        sessionStorage.setItem(FakeBackendInterceptor.USER_ID, JSON.stringify(users));
        return of(new HttpResponse({ status: 200 }));
      }
      /* --------------------------------------------------
       *      Trainings Funktionen
       * --------------------------------------------------*/
      if (request.url.match(/\/trainings\/\d+-\d+-\d+/) && request.method === 'GET') {
        let urlParts: string[] = request.url.split("/");
        let requestedDate: string = urlParts[urlParts.length - 1];
        this.log.info("Lade Trainingsdaten für " + requestedDate);
        let filteredStoredTrainings: TrainingEntry[] = [];
        if (sessionStorage.getItem(FakeBackendInterceptor.TRAINING_ID)) {
          let storedTrainings: TrainingEntry[] = JSON.parse(sessionStorage.getItem(FakeBackendInterceptor.TRAINING_ID));
          filteredStoredTrainings = storedTrainings.filter((entry: TrainingEntry) => {
            let entryDate: string = entry.date.toString().split("T")[0];
            return entryDate == requestedDate;
          });
        }
        this.log.info(filteredStoredTrainings.length + " gespeicherte Trainings gefunden");
        return of(new HttpResponse({ status: 200, body: filteredStoredTrainings }));
      }
      // Training speichern
      if (request.url.endsWith('/trainings') && request.method === 'POST') {
        // find if any user matches login credentials
        let newTraining: TrainingEntry = request.body;
        this.log.info("Speichere Training: " + JSON.stringify(newTraining));
        let storedTrainings: TrainingEntry[] = [];
        if (JSON.parse(sessionStorage.getItem(FakeBackendInterceptor.TRAINING_ID))) {
          storedTrainings = JSON.parse(sessionStorage.getItem(FakeBackendInterceptor.TRAINING_ID));
        }
        storedTrainings.push(newTraining);
        sessionStorage.setItem(FakeBackendInterceptor.TRAINING_ID, JSON.stringify(storedTrainings));
        return of(new HttpResponse({ status: 200 }));
      }
      // Trainings aktualisieren
      if (request.url.endsWith('/trainings') && request.method === 'PUT') {
        // find if any user matches login credentials
        let existingTraining: TrainingEntry = request.body;
        this.log.info("Aktualisiere Training: " + JSON.stringify(existingTraining));
        let trainings: TrainingEntry[] = JSON.parse(sessionStorage.getItem(FakeBackendInterceptor.TRAINING_ID));
        for (let i: number = 0; i < trainings.length; i++) {
          if (trainings[i].date.toString() == existingTraining.date.toISOString()) {
            this.log.debug("Training gefunden und aktualisiert");
            trainings[i] = existingTraining;
          }
        }
        sessionStorage.setItem(FakeBackendInterceptor.TRAINING_ID, JSON.stringify(trainings));
        return of(new HttpResponse({ status: 200 }));
      }
      /* --------------------------------------------------
       *      Tägliche Einträge Funktionen
       * --------------------------------------------------*/
      // täglichen Eintrag laden
      if (request.url.match(/\/daily\/\d+-\d+-\d+/) && request.method === 'GET') {
        let urlParts: string[] = request.url.split("/");
        let requestedDate: string = urlParts[urlParts.length - 1];
        this.log.info("Lade Tagesdaten für " + requestedDate);
        let filteredStoredDaily: DailyEntry;
        if (sessionStorage.getItem(FakeBackendInterceptor.DAILY_ID)) {
          let storedDaily: DailyEntry = JSON.parse(sessionStorage.getItem(FakeBackendInterceptor.DAILY_ID));
          if (requestedDate == storedDaily.date.toString().split("T")[0]) {
            filteredStoredDaily = storedDaily;
            this.log.info("Gespeicherter Tageseintrag gefunden: " + JSON.stringify(storedDaily));
          }
        }
        return of(new HttpResponse({ status: 200, body: filteredStoredDaily }));
      }
      // Training speichern
      if (request.url.endsWith('/daily') && request.method === 'POST') {
        // find if any user matches login credentials
        let newDaily: DailyEntry = request.body;
        this.log.info("Speichere Tageseintrag: " + JSON.stringify(newDaily));
        sessionStorage.setItem(FakeBackendInterceptor.DAILY_ID, JSON.stringify(newDaily));
        return of(new HttpResponse({ status: 200 }));
      }
      // Trainings aktualisieren
      if (request.url.endsWith('/trainings') && request.method === 'PUT') {
        // find if any user matches login credentials
        let existingDaily: DailyEntry = request.body;
        this.log.info("Aktualisiere Tageseintrag: " + JSON.stringify(existingDaily));
        sessionStorage.setItem(FakeBackendInterceptor.DAILY_ID, JSON.stringify(existingDaily));
        return of(new HttpResponse({ status: 200 }));
      }
      /* --------------------------------------------------
       *      Report Funktionen
       * --------------------------------------------------*/
      if (request.url.match(/\/report\/load\/sum\?start=\d+-\d+-\d+&end=\d+-\d+-\d+/) && request.method === 'GET') {
        let httpParams:HttpParams = new HttpParams({ fromString: request.url.split('?')[1] });
        let startDate: string = httpParams.get("start");
        let endDate: string = httpParams.get("end");
        this.log.info("Report Anfrage für Trainingslast und -dauer pro Kategorie (Startdatum:" + startDate + ",Enddatum:" + endDate + ")");
        return this.getDataFromFileOrVariable("assets/test/training-load-sum-category.json", FakeBackendInterceptor.REPORT_LOAD_CATEGORY_ID);
      }
      if (request.url.match(/\/report\/load\/sum\/\w+\?start=\d+-\d+-\d+&end=\d+-\d+-\d+/) && request.method === 'GET') {
        let urlPartsWhole:string[] = request.url.split('?');
        let httpParams:HttpParams = new HttpParams({ fromString: urlPartsWhole[1] });
        let startDate: string = httpParams.get("start");
        let endDate: string = httpParams.get("end");
        let urlParts: string[] = urlPartsWhole[0].split("/");
        let requestedCategory: string = urlParts[urlParts.length - 1];
        this.log.info("Report Anfrage für Trainingslast und -dauer pro Methode  (Kategorie:" + requestedCategory + ",Startdatum:" + startDate + ",Enddatum:" + endDate + ")");
        return this.getDataFromFileOrVariable("assets/test/training-load-sum-method.json", FakeBackendInterceptor.REPORT_LOAD_METHOD_ID);
      }
      if (request.url.match(/\/report\/load\/week\?start=\d+&end=\d+/) && request.method === 'GET') {
        let httpParams:HttpParams = new HttpParams({ fromString: request.url.split('?')[1] });
        let startWeek: number = parseInt(httpParams.get("start"),10);
        let endWeek: number = parseInt(httpParams.get("end"),10);
        this.log.info("Report Anfrage für Trainingslast und -dauer pro Woche (Startwoche:" + startWeek + ",Endwoche:" + endWeek + ")");
        return this.getDataFromFileOrVariable("assets/test/training-load-sum.json", FakeBackendInterceptor.REPORT_LOAD_WEEK_ID);
      }
      if (request.url.match(/\/report\/load\/week\/details\?week=\d+/) && request.method === 'GET') {
        let httpParams:HttpParams = new HttpParams({ fromString: request.url.split('?')[1] });
        let week: number = parseInt(httpParams.get("week"),10);
        this.log.info("Report Anfrage für Trainingslast und -dauer Details für eine Woche (Woche:" + week + ")");
        return this.getDataFromFileOrVariable("assets/test/training-load-sum-week.json", FakeBackendInterceptor.REPORT_LOAD_WEEK_DETAILS_ID);
      }
      if (request.url.match(/\/report\/basics\?start=\d+-\d+-\d+&end=\d+-\d+-\d+/) && request.method === 'GET') {
        let urlPartsWhole:string[] = request.url.split('?');
        let httpParams:HttpParams = new HttpParams({ fromString: urlPartsWhole[1] });
        let startDate: string = httpParams.get("start");
        let endDate: string = httpParams.get("end");
        this.log.info("Report Anfrage für Basisdaten  (Startdatum:" + startDate + ",Enddatum:" + endDate + ")");
        return this.getDataFromFileOrVariable("assets/test/training-basics.json", FakeBackendInterceptor.REPORT_BASICS);
      } 
      // alle anderen Requests einfach weiterleiten
      return next.handle(request);
    }))
  }
}

export let fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};

export let noopProvider = {
  // use a noop provider as placeholder
  provide: HTTP_INTERCEPTORS,
  useClass: class noopInterceptor implements HttpInterceptor{
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request);
    }
  } ,
  multi: true
};