import { Component, OnInit, Host } from '@angular/core';
import { TrainingEntry } from 'src/app/_model/entries';
import { SelectItem } from 'primeng/api';
import { TrainingCategory } from 'src/app/_model/category';
import { LogService } from 'src/app/_helper/messages';
import { TrainingConnector } from 'src/app/_service/trainings';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';
import { CaptureChildComponent } from 'src/app/report/interface';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
/**
 * Diese Klasse ist der Controller für die Erfassung und Anzeige eines Trainings.
 */
export class TrainingComponent implements OnInit, CaptureChildComponent {
  /**
   * die ausgewählte Trainingszeit
   */
  trainingTime: string;
  /**
   * die JSON Einträge aus der Datenbank, welche die Trainings an einem Tag enthalten
   */
  storedTrainings: TrainingEntry[] = [];
  /**
   * die möglichen Trainingszeiten
   */
  trainingTimes: SelectItem[] = [];
  /**
   * die JSON Datei, welche die Trainingskategorien und -methoden enthält
   */
  categories: TrainingCategory[] = [];
  /**
   * die Methoden ausgewählt aus den Kategorien
   */
  methods: SelectItem[] = [];
  /**
   * die möglichen Wörter für das Belastungsempfinden
   */
  private static RPE_WORDS: String[] = ["keine Beanspruchung",
    "leicht",
    "mittel",
    "etwas mehr als Mittel",
    "schwere Belastung",
    "das schwerste Training überhaupt"]
  /**
   * die Wort für die Belastung
   */
  rpeWording: String;
  /**
   * der zugehörige Trainingseintrag
   */
  training: TrainingEntry = new TrainingEntry();

  constructor(private connector: TrainingConnector,
    private message: LogService, 
    private log: NGXLogger) { }

  ngOnInit() {
    this.connector.loadTrainingCategoryData().subscribe(
      (categories: TrainingCategory[]) => this.categories = categories,
      (error: Error) => {
        this.message.logError(
          'Datenbankfehler',
          'Die Kategoriedaten konnten nicht geholt werden (Fehler:' + error.message + ')'
        );
      });
  }

  init(date: Date) {
    // Datum
    this.training.date = new Date(date);
    // die Liste der Kategorien speichern
    // Trainings für heute laden
    this.loadTrainings();
  }
  /**
   * Diese Funktion wählt die Liste der Methoden basierend auf der Auswahl der Kategorie.
   */
  loadMethods() {
    // bei der Filterung gibt es immer nur ein Ergebnis
    this.methods = this.categories.filter((category: TrainingCategory) => { return category.value === this.training.category })[0].methods;
  }
  /**
   * Diese Funktion lädt die Trainings zu einem Datum.
   */
  loadTrainings(notifyUser:boolean=true) {
    this.trainingTimes = [];
    this.connector.loadTrainings(this.training.date)
      .subscribe((entries: TrainingEntry[]) => {
        // trainingsdaten für den Tag holen
        if (entries.length > 0) {
          this.storedTrainings = entries;
          if (notifyUser){
            this.message.logInfo(
              'Datensätze vorhanden',
              'Es gibt schon ein oder mehrere Trainings für den ausgewählten Tag.'
            );
          }
          this.trainingTimes = this.storedTrainings.map((entry: TrainingEntry) => {
            return {
              label: entry.date.toLocaleString(),
              value: entry.date.toString()
            }
          });
        }
      },
        (error: HttpErrorResponse) => {
          this.message.logHttpError(
            'Datenbankfehler',
            "Die Trainingsdaten konnten nicht geholt werden", error);
        },
        () => {
          // ein leeres Training ergänzen
          let emptyTraining: TrainingEntry = new TrainingEntry();
          emptyTraining.date.setFullYear(
            this.training.date.getFullYear(),
            this.training.date.getMonth(),
            this.training.date.getDate()
          );
          this.storedTrainings.push(emptyTraining);
          this.trainingTimes.push({
            label: "Jetzt",
            value: emptyTraining.date.toString()
          });
          this.trainingTime = emptyTraining.date.toString();
          // Trainingsdaten setzen
          this.training = emptyTraining;
        }
      );
  }
  /**
   * Diese Funktion lädt ein Training nach dem Namen (wenn zuvor ein Datum ausgewählt wurde)
   */
  openTrainingData() {
    let filterResult: TrainingEntry[] = this.storedTrainings.filter((training: TrainingEntry) => training.date.toString() === this.trainingTime);
    if (filterResult.length > 0) {
      // on Select event
      this.training = filterResult[0];
      // Trainingsmethoden laden
      if (this.training.category) {
        this.loadMethods();
      }
    }
  }

  validateData():boolean{
    // Wochennummer setzen
    this.training.week = moment(this.training.date).isoWeek();
    this.training.year = moment(this.training.date).year();
    return this.training.valid();
  }

  storeData(){
    if (this.training.newEntry) {
      // Neuen Benutzer speichern
      this.executeRequest(this.connector.storeTraining(this.training), this.training.date);
    }
    else {
      // Benutzer aktualisieren (key ist vorhanden)
      this.executeRequest(this.connector.updateTraining(this.training), this.training.date);
    }
  }
  /**
  * Diese Funktion führt einen speichern oder aktualisieren Request aus
  * @param request der Request
  * @param selectedTrainingTime die ausgewählte Trainingszeit vor dem Request
  */
  private executeRequest(request: Observable<HttpResponse<any>>, selectedTrainingTime: Date) {
    request.subscribe(
      (response: HttpResponse<any>) =>
        this.message.logSuccess("Erfolg", "Das Training (" + this.training.date + " in der Kategorie \"" + this.training.category + "\") wurde erfolgreich gespeichert."),
      (error: HttpErrorResponse) => {
        this.message.logHttpError("Fehler", "Das Training konnte nicht gespeichert werden", error);
      },
      () => {
        // den Benutzer nicht beim erneuten Laden informieren, dass Datensätze da sind
        this.loadTrainings(false);
        // Auswahl im Zeitfeld wieder auf den eingegebenen Wert setzen
        this.trainingTime = selectedTrainingTime.toString();
      }
    );
  }
  /**
   * Diese Funktion ändert den Wortlaut für die subjektive Belastung entsprechend
   */
  changeRPEWording() {
    this.rpeWording = TrainingComponent.RPE_WORDS[this.training.rpe - 1];
  }
}
