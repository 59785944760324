import { Component, OnInit } from '@angular/core';
import { MenuItem, SelectItem } from 'primeng/api';
import { UserConnector } from '../_service/users';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  /**
   * die Menüeinträge
   */
  items: MenuItem[];

  constructor(private connector:UserConnector, private router: Router) {
   }

  ngOnInit() {
    // Menü ändern falls sich der Benutzer ändert
    this.connector.validCurrentUser.subscribe((value:boolean) => this.initMenu());
    this.initMenu();
  }
  /**
   * Diese Funktion initialisiert das Menü
   */
  public initMenu(){
    this.items = [
      {label: 'Basisdaten', routerLink:'base'}, 
      {label: 'Erfassung', routerLink:'capture'}, 
      {label: 'Abmelden', icon: 'fa fa-sign-out', command:((event) => this.logout())},
      {label: 'Impressum', routerLink:'impressum'}
    ];
    if (this.connector.getCurrentUser() && this.connector.getCurrentUser().trainer){
      this.items.push({label: 'Administration', icon: 'fa fa-lock', routerLink:'admin'});
      this.items.push({label: 'Auswertung', icon: 'fa fa-lock', routerLink:'report'});
    }
  }

  logout(){
    this.connector.logoutUser();
    // zur Startseite zurück
    this.router.navigate(['login']);
  }
}
