import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'zeroFormat' })
/**
 * Diese Klasse formatiert 0 Zahlen zu leeren Feldern.
 */
export class ZeroNumberPipe implements PipeTransform {

  transform(aNumber:number):string {
      if (aNumber > 0){
          return aNumber.toString();
      }
      else
        return "";
  }
}