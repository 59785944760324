import { Injectable } from '@angular/core';
import { DailyEntry } from '../_model/entries';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UserConnector } from './users';
import { formatDate } from '@angular/common';
import { environment } from '../../environments/environment';

/**
 * Diese Klasse speichert und aktualisiert die täglichen Einträge.
 */
@Injectable()
export class DailyConnector {
  /**
   * der ID String für den Daily Eintrag im local storage
   */
  public static DAILY_ID: string = "daily";

  constructor(private http: HttpClient, private connector: UserConnector) { }

  public loadDaily(date: Date): Observable<DailyEntry> {
    return this.http.get<DailyEntry>(environment.backend_root + '/daily/' + formatDate(date,"yyyy-MM-dd","en"), { headers: this.connector.getAuthHeaders()})
    // muss auf Klasse gemapped werden, da sonst Date und die Funktion valid nicht funktionieren
    .pipe(
      map<any,DailyEntry>((data) => new DailyEntry(data))
      );
  }
  /**
   * Diese Funktion speichert einen (neuen) täglichen Eintrag.
   * @param entry der tägliche Eintrag
   */
  public storeDaily(entry: DailyEntry) {
    return this.http.post<DailyEntry>(environment.backend_root +'/daily', entry, { headers: this.connector.getAuthHeaders(), observe: 'response' });
  }
  /**
* Diese Funktion aktualisiert einen täglichen Eintrag.
* @param entry der (bestehende) tägliche Eintrag
*/
  public updateDaily(entry: DailyEntry): Observable<HttpResponse<any>> {
    return this.http.put<DailyEntry>(environment.backend_root + '/daily', entry, { headers: this.connector.getAuthHeaders(), observe: 'response' });
  }
  /**
   * Diese Funktion aktualisiert den EWMA.
   * @param date das Datum
   */
  public updateEWMA(date:Date){
    return this.http.post<DailyEntry>(environment.backend_root + '/daily/ewma/' + formatDate(date,"yyyy-MM-dd","en"), null, { headers: this.connector.getAuthHeaders(), observe: 'response' });
  }
}