import { Chart, Tooltip, ChartElement } from 'chart.js';
/**
 * Diese Klasse beschreibt die Farben des Reports.
 */
export class ReportOptions {
  /**
   * der Array von Farben für die Kategorien
   * http://colorbrewer2.org/#type=diverging&scheme=PuOr&n=11
   */
  static PRIMARY_COLORS: string[] =
    ['#7f3b08', '#b35806', '#e08214', '#fdb863', '#fee0b6', '#f7f7f7', '#d8daeb', '#b2abd2', '#8073ac', '#542788', '#2d004b'];
  /**
   * die Sekundärfarben
   */
  public static SECONDARY_COLORS: string[] =
    ['#2d004b', '#542788', '#8073ac', '#b2abd2', '#d8daeb', '#f7f7f7', '#fee0b6', '#fdb863', '#e08214', '#b35806', '#7f3b08'];
  /**
   * die allgemeinen Optionen für die Charts
   */
  static CHART_OPTIONS: any = {
    // alle Animationen ausschalten
    //animation: false,
    hover: {
      // tooltip animation ausschalten
      animationDuration: 0
    },
    scales: {
      yAxes: [{
          ticks: {
              suggestedMin: 0
          }
      }]
    },
    title: {
      display: false,
      fontSize: 16,
      fontFamily: "'Open Sans', 'Helvetica Neue', 'sans-serif'"
    },
    // padding, damit alle Labels dargestellt werden
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 50,
        bottom: 0
      }
    },
    tooltips: {
      // Tooltip position bei allen außer Pie Charts
      yAlign: 'top',
      xAlign: 'middle',
      cornerRadius: 0,
      caretSize: 0,
      xPadding: 10,
      yPadding: 10,
      displayColors: false,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      position: "custom",
      callbacks: {
        title: function (tooltipItem, data) {
          // Überprüfe, on für diese Graphik überhaupt ein Titel gesetzt werden soll
          if (!data['datasets'][tooltipItem[0]['datasetIndex']]['customNoTitle']) {
            return data['labels'][tooltipItem[0]['index']];
          }
          else {
            return null;
          }

        },
        label: function (tooltipItem, data) {
          if (data['datasets'][tooltipItem['datasetIndex']]['customPostfix']){
            return data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']] +
              data['datasets'][tooltipItem['datasetIndex']]['customPostfix'];
          }
          else {
            return String(data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']]);
          }
        },
      }
    },
    // Dognut Chart cutout
    cutoutPercentage: 40,
    legend: {
      position: "bottom",
      labels: {
        fontSize: 16,
        fontFamily: "'Open Sans', 'Helvetica Neue', 'sans-serif'"
      }
    }
    ,
    elements: {
      arc: {
        // standard colors (werden im Datenset überschrieben)
        backgroundColor: ReportOptions.PRIMARY_COLORS
      }
    }
  }
  /**
   * Diese Funktion registriert im Chartjs, die statischen Tooltips.
   */
  static registerStaticTooltips() {
    // Tooltip position (funktioniert nur für Einzelelemente und keine Stacks)
    Chart.Tooltip.positioners.custom = function (elements:ChartElement[], eventPosition) {
      // nur ein Element wird unterstützt
      let element:ChartElement = elements[0];
      let xOffset:number = element._chart.chart.config.data.datasets[element._datasetIndex].customXOffset;
      let yOffset:number = element._chart.chart.config.data.datasets[element._datasetIndex].customYOffset;
      let toolTipPosition = element.tooltipPosition();
      // der Typ des Charts
      let chartType:string = element._chart.config.type;
      // Standardpositionen für average
      let xPosition:number = toolTipPosition.x;
      let yPosition:number = toolTipPosition.y;
      if ((chartType == "bar") || (chartType == "line")){
        // bar Chart
        xPosition = toolTipPosition.x - xOffset;
        yPosition = toolTipPosition.y - yOffset;
      }
      else if (chartType == "doughnut"){
        let center = element.getCenterPoint();
        xPosition = center.x - xOffset;
        yPosition = center.y - yOffset;        
      }
      return {
        x: xPosition,
        y: yPosition
      };
    };

    Chart.pluginService.register({
      beforeRender: function (chart: Chart) {
        if (chart.config.options.tooltips.enabled) {
          // Tooltips abspeichern
          https://stackoverflow.com/questions/36992922/chart-js-v2-how-to-make-tooltips-always-appear-on-pie-chart
          chart.pluginTooltips = [];
          chart.config.data.datasets.forEach(function (dataset, i) {
            chart.getDatasetMeta(i).data.forEach(function (sector, j) {
              chart.pluginTooltips.push(new Chart.Tooltip({
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [sector]
              }, chart));
            });
          });
          // normale Tooltips abschalten
          chart.options.tooltips.enabled = false;
        }
      },
      afterDraw: function (chart, easing) {
        // erst wenn es wirklich Tooltip elemente gibt
        if (chart.pluginTooltips.length > 0) {
          // keine Animation also erst nach der Animation anzeigen
          if (!chart.allTooltipsOnce) {
            if (easing !== 1)
              return;
            chart.allTooltipsOnce = true;
          }
          // turn on tooltips
          chart.options.tooltips.enabled = true;
          Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
            tooltip.initialize();
            tooltip.update();
            // we don't actually need this since we are not animating tooltips
            tooltip.pivot();
            tooltip.transition(easing).draw();
          });
          chart.options.tooltips.enabled = true;
        }
      }
    });
  }
}

/**
 * Diese Interface bezeichnet eine Komponente, welche eine Graphik zeichnen kann. (Das ist jede
 * Komponente unter der Report Komponente)
 */
export interface GraphDrawer {
  /**
   * Diese Funktion zeichnet den Graphen.
   * @param startDate das Startdatum
   * @param endDate das Enddatum
   * @param user der Benutzer für den die Abfrage durchgeführt werden soll
   */
  drawGraph(startDate: Date, endDate: Date, user:number);
}
/**
 * Dieses Interface bezeichnet eine Kind-Komponente für die Erfassung von Trainings- und Tagesdatensätzen.
 */
export interface CaptureChildComponent {
  /**
   * Diese Funktion initialisiert die Komponente auf Basis des Datums
   * @param date das gewünschte Datum
   */
  init(date:Date);
  /**
   * Diese Funktion validiert die Eingabedaten.
   * @returns wahr, falls die Validierung erfolgreich war
   */
  validateData():boolean;
  /**
   * Diese Funktion speichert die Daten
   */
  storeData();
}