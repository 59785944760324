import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphDrawer, ReportOptions } from '../interface';
import { ReportConnector } from 'src/app/_service/reports';
import { UIChart } from 'primeng/chart';
import { LogService } from 'src/app/_helper/messages';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.css']
})
export class LoadComponent implements OnInit, GraphDrawer {
  /**
   * das Chart Objekt für Kategorien
   */
  @ViewChild('categoryChart') categoryChart: UIChart;
  /**
   * das Chart Objekt für Methoden
   */
  @ViewChild('methodChart') methodChart: UIChart;
  /**
   * der Benutzer
   */
  user:number
  /**
   * der Name der Kategorie
   */
  category: string;
  /**
   * die Daten für die Kategorie
   */
  dataCategory: any;
  /**
   * die Daten für die Methode
   */
  dataMethod: any;
  /**
   * das Startdatum (der Abfrage)
   */
  startDate:Date;
  /**
   * das Enddatum (der Abfrage)
   */
  endDate:Date;

  constructor(private connector: ReportConnector, private message: LogService) { }

  ngOnInit() {
    // Farben für Kategorie Dognut setzen
    this.categoryChart.options = ReportOptions.CHART_OPTIONS;
    // Optionen für Methoden Dognut  
    this.methodChart.options = ReportOptions.CHART_OPTIONS;
  }

  drawGraph(startDate: Date, endDate: Date, user:number) {
    this.user = user;
    this.dataMethod = null;
    // Datumsauswahl lokal speichern (für Methodenauswahl)
    this.startDate = startDate;
    this.endDate = endDate;
    this.connector.loadLoadByCategorySum(startDate, endDate, this.user).subscribe(
      (data: any[]) => {
        let categories: string[] = [];
        let durations: number[] = [];
        let loads: number[] = [];
        data.forEach((dataItem: any) => {
          categories.push(dataItem.category);
          durations.push(dataItem.duration);
          loads.push(dataItem.load);
        });
        // Tortendiagramm Daten erstellen
        this.dataCategory = {
          labels: categories,
          datasets: [
            {
              backgroundColor: ReportOptions.PRIMARY_COLORS,
              borderWidth: 10,
              data: durations,
              customPostfix: ' h',
              customXOffset: 20,
              customYOffset: 20
            },
            {
              backgroundColor: ReportOptions.PRIMARY_COLORS,
              borderWidth: 10,
              data: loads,
              customXOffset: 20,
              customYOffset: 20
            }
          ]
        };
      },
      (error:HttpErrorResponse) => {
        this.message.logError("Ladefehler", "Die nötigen Daten konnten nicht geladen werden: " + error.message);
      })
  }
  /**
   * Diese Funktion lädt die zugehörige Methodenaus (bei Click)
   * @param event das Event aus dem Kategorie Chart
   */
  loadMethodData(event) {
    this.category = this.dataCategory.labels[event.element._index];
    // die Kategoriebezeichnung auslesen
    this.connector.loadLoadByMethodSum(this.startDate, this.endDate, this.category, this.user).subscribe(
      (data: any[]) => {
        let methods: string[] = [];
        let durations: number[] = [];
        let loads: number[] = [];
        data.forEach((dataItem: any) => {
          methods.push(dataItem.method);
          durations.push(dataItem.duration);
          loads.push(dataItem.load);
        });
        // Tortendiagramm Daten erstellen
        this.dataMethod = {
          labels: methods,
          datasets: [
            {
              backgroundColor: ReportOptions.SECONDARY_COLORS,
              borderWidth: 10,
              data: durations,
              customPostfix: ' h',
              customXOffset: 40,
              customYOffset: 20
            },
            {
              backgroundColor: ReportOptions.SECONDARY_COLORS,
              borderWidth: 10,
              data: loads,
              customXOffset: 40,
              customYOffset: 20
            }
          ]
        };
      },
      (error:HttpErrorResponse) => {
        this.message.logError("Ladefehler", "Die nötigen Daten konnten nicht geladen werden: " + error.message);
      })
  }
}
