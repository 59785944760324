import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserConnector } from './users';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

/**
 * Diese Klasse stellt die Verbindung zum Backend für Reports her.
 */
@Injectable()
export class ReportConnector {

  constructor(private http: HttpClient, private connector: UserConnector) { }
  /**
   * Diese Funktion lädt die Trainingslast nach Kategorien
   */
  loadLoadByCategorySum(startDate: Date, endDate: Date, user: number): Observable<any[]> {
    return this.http.get<any[]>(environment.backend_root + '/report/load/sum?start='
      + formatDate(startDate, "yyyy-MM-dd", "en") + "&end=" + formatDate(endDate, "yyyy-MM-dd", "en")
      + "&user=" + user, { headers: this.connector.getAuthHeaders() });
  }
  /**
   * Diese Funktion lädt die Trainingslast nach Kategorien und auf Wochenbasis
   */
  loadLoad(startWeek: number, startYear: number, endWeek: number, endYear: number, user: number): Observable<any[]> {
    return this.http.get<any[]>(environment.backend_root + '/report/load/week?start='
      + startWeek
      + "&startyear=" + startYear
      + "&end=" + endWeek
      + "&endyear=" + endYear
      + "&user=" + user, { headers: this.connector.getAuthHeaders() });
  }
  /**
   * Diese Funktion lädt die Trainingslast nach Methoden in einer Kategorie.
   * @param startDate das Startdatum
   * @param endDate das Enddatum
   * @param category die Kategorie
   */
  loadLoadByMethodSum(startDate: Date, endDate: Date, category: string, user: number): Observable<any[]> {
    return this.http.get<any[]>(environment.backend_root + '/report/load/sum/' + category + "?start="
      + formatDate(startDate, "yyyy-MM-dd", "en") + "&end=" + formatDate(endDate, "yyyy-MM-dd", "en")
      + "&user=" + user, { headers: this.connector.getAuthHeaders() });
  }
  /**
   * Diese Funktion lädt die Trainingslast nach Methoden in einer Kategorie (innerhalb einer Woche)
   */
  loadLoadByCategory(week: number, year: number, user: number): Observable<any[]> {
    return this.http.get<any[]>(environment.backend_root + '/report/load/week/details?week='
      + week 
      + "&year=" + year
      + "&user=" + user, { headers: this.connector.getAuthHeaders() });
  }
  /**
   * Diese Funktion lädt die Basisdaten für einen Zeitraum
   * @param startDate das Startdatum
   * @param endDate das Enddatum
   */
  loadBasics(startDate: Date, endDate: Date, user: number): Observable<any[]> {
    return this.http.get<any[]>(environment.backend_root + "/report/basics?start="
      + formatDate(startDate, "yyyy-MM-dd", "en")
      + "&end=" + formatDate(endDate, "yyyy-MM-dd", "en")
      + "&user=" + user, { headers: this.connector.getAuthHeaders() });
  }
}