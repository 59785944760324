import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphDrawer, ReportOptions } from '../interface';
import { UIChart } from 'primeng/chart';
import * as moment from 'moment';
import { ReportConnector } from 'src/app/_service/reports';
import { LogService } from 'src/app/_helper/messages';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit, GraphDrawer {
  /**
   * der Diagrammtyp, der angezeigt werden kann
   */
  diagramType: any;
  /**
   * das ausgewählte Diagramm
   */
  selectedDiagramType: any = { label: 'Trainingslast', value: 'loads' , postfix: ''};
  /**
   * die Diagrammdaten
   */
  graphData: any;
  /**
   * die Basisdaten
   */
  baseData: any = {};
  /**
   * die Detail-Diagrammdaten
   */
  detailsData: any;
  /**
   * Wochennummer für die Überschrift bei der Kategoriedetailansicht
   */
  week: number = 0;
  /**
   * das Jahr für die Überschrift bei der Kategoriedetailansicht
   */
  year: number = 0;
  /**
   * der Name des Details (Trainingslast oder -dauer)
   */
  detailsName: string;
  /**
   * der Benutzer, für welchen die Daten angezeigt werden sollen
   */
  user:number;
  /**
   * das Chart Objekt die Wochenansicht der Trainingslast und -dauer
   */
  @ViewChild('timelineChart') timelineChart: UIChart;
  /**
 * das Chart Objekt für die detailierte Ansicht einer Woche
 */
  @ViewChild('detailsChart') detailsChart: UIChart;

  constructor(private connector: ReportConnector, private message: LogService) { }

  ngOnInit() {
    this.diagramType = [
      { label: 'Trainingslast', value: 'loads' , postfix: ''},
      { label: 'Trainingsdauer', value: 'durations', postfix: ' h' }
    ];
    // Farben für Kategorie Dognut setzen
    this.timelineChart.options = ReportOptions.CHART_OPTIONS;
    // Optionen für das Details Chart setzen
    this.detailsChart.options = ReportOptions.CHART_OPTIONS;
  }

  drawGraph(startDate: Date, endDate: Date, user:number) {
    this.user = user;
    // Detaildaten zurücksetzen
    this.detailsData = null;
    this.week = 0;
    this.detailsName = null;
    // Wochenwerte aus dem Datum ermitteln
    let startWeek = moment(startDate).isoWeek();
    let startYear = moment(startDate).year()
    let endWeek = moment(endDate).isoWeek();
    let endYear = moment(endDate).year();
    // Daten holen
    this.connector.loadLoad(startWeek, startYear, endWeek, endYear, this.user).subscribe(
      (data: any[]) => {
        this.baseData.weeks = [];
        this.baseData.durations = [];
        this.baseData.loads = [];
        data.forEach((dataItem: any) => {
          this.baseData.weeks.push("KW " + dataItem.week + " " + dataItem.year);
          this.baseData.durations.push(dataItem.duration);
          this.baseData.loads.push(dataItem.load);
        });
      },
      (error:HttpErrorResponse) => {
        this.message.logError("Ladefehler", "Die nötigen Daten konnten nicht geladen werden: " + error.message);
      },
      () => {
        // Graph anzeigen
        this.changeGraph();
      }
    );
  }
  /**
   * Diese Funktion ändert die Anzeige in der Graphik
   */
  changeGraph() {
    this.graphData = {
      labels: this.baseData.weeks,
      datasets: [
        {
          label: this.selectedDiagramType.label,
          backgroundColor: ReportOptions.PRIMARY_COLORS[2],
          borderWidth: 5,
          data: this.baseData[this.selectedDiagramType.value],
          customNoTitle: true,
          customPostfix: this.selectedDiagramType.postfix,
          customXOffset: 25,
          customYOffset: 35
        }
      ]
    };
  }
  /**
   * Diese Funktion lädt die Details für eine Woche.
   * @param event das Event
   */
  loadWeekDetailData(event) {
    this.week = parseInt(this.graphData.labels[event.element._index].split(" ")[1], 10);
    this.year = parseInt(this.graphData.labels[event.element._index].split(" ")[2], 10);
    // der Name der zugehörigen Variable, von der die Daten abgerufen werden
    let memberName: string = ["load", "duration"][event.element._datasetIndex];
    this.detailsName = ["Trainingslast", "Trainingsdauer"][event.element._datasetIndex];
    this.connector.loadLoadByCategory(this.week, this.year, this.user).subscribe(
      (data: any[]) => {
        // die Details für eine Kategorie (muss von den Originaldaten umformatiert werden)
        let categoryDetails: any[] = [];
        // die Wochentage für die Legende
        let weekdays: string[] = [];
        // Wochentage initialisieren (nach der ISO Wochenbezeichnung)
        for (let i:number = 0; i <= 6; i++){
          weekdays.push(moment(this.week + "-" + this.year, "w-YYYY").add(i,'days').format("DD.MM.YYYY"));
        }
        console.log(weekdays);
        data.forEach((dataItem: any) => {
          // Tag der Woche herausfinden
          //weekdays.push(moment(dataItem.day, "YYYY-MM-DD").format("DD.MM.YYYY"));
          let weekday: number = moment(dataItem.day, "YYYY-MM-DD").day();
          // Details parsen (hier stehen die Kategorien und deren Werte)
          dataItem.details.forEach((detailsItem: any) => {
            if (!categoryDetails[detailsItem.category]) {
              categoryDetails[detailsItem.category] = [];
              // leer initialisieren, damit alle Werte an der richtigen Stelle stehen
              for (let i = 0; i < 7; i++) {
                categoryDetails[detailsItem.category][i] = 0;
              }
            }
            categoryDetails[detailsItem.category][weekday] = Number(detailsItem[memberName]);
          })
        });
        console.log(categoryDetails);
        this.detailsData = {
          labels: weekdays,
          datasets: []
        }
        let index: number = 0;
        for (let key in categoryDetails) {
          let dataItem: any[] = categoryDetails[key];
          this.detailsData.datasets.push({
            label: key,
            backgroundColor: ReportOptions.SECONDARY_COLORS[index],
            borderWidth: 5,
            data: dataItem,
            customNoTitle: true,
            customXOffset: 17,
            customYOffset: 35
          });
          index++;
        }
      },
      (error:HttpErrorResponse) => {
        this.message.logError("Ladefehler", "Die nötigen Daten konnten nicht geladen werden: " + error.message);
      })
  }
}