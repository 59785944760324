import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';
import { WeekDay } from '@angular/common';

/**
 * Diese Klasse repräsentiert einen Tageseintrag.
 */
export class DailyEntry {
  /**
   * wahr, falls es sich um einen neuen Eintrag handelt
   */
  public new:boolean = false;
  /**
   * das Datum
   */
  public date: Date;
  /**
   * die zugehörige Wochennummer
   */
  public week: number = 0;
  /**
   * die Herzfrequenz
   */
  public heartrate: number = 0;
  /**
   * das Gewicht
   */
  public weight: number = 0;
  /**
   * die Stimmung
   */
  public mood: number = 0;
  /**
   * der Schlaf
   */
  public sleep: number = 0;
  /**
   * der Standard Konstruktor
   */
  constructor(httpRemoteObject: DailyEntry = null, date: Date = new Date()) {
    if (httpRemoteObject) {
      this.heartrate = httpRemoteObject.heartrate;
      this.mood = httpRemoteObject.mood;
      this.sleep = httpRemoteObject.sleep;
      this.weight = httpRemoteObject.weight;
      this.week = httpRemoteObject.week;
      this.date = new Date(httpRemoteObject.date);
      this.new = false;
    }
    else {
      this.date = date;
      this.new = true;
    }
  }
  /**
   * Diese Funktion validiert die Eingabeklasse.
   * @returns true falls, die Klasse korrekt ist und false, falls sie Eingabefehler aufweist.
   */
  public valid(): boolean {
    if (this.date &&
      this.heartrate > 0 &&
      this.mood > 0 &&
      this.week > 0 &&
      this.sleep > 0 &&
      this.weight > 0) {
      return true;
    }
    else {
      return false;
    }
  }
}
/**
 * Diese Klasse repräsentiert einen einzelnen Trainingseintrag.
 */
export class TrainingEntry {
  /**
   * zeigt an, dass es sich um ein neues Training handelt
   */
  public newEntry:boolean = false;
  /**
   * die zugehörige Wochennummer
   */
  public week: number = 0;
  /**
   * das zugehörige Jahr
   */
  public year:number = 0;
  /**
   * das Trainingsdatum
   */
  public date: Date;
  /**
   * die Trainingskategorie
   */
  public category: string;
  /**
   * die Methode innerhalb der Kategorie
   */
  public method: string;
  /**
   * die Dauer
   */
  public duration: number = 0;
  /**
   * das Belastungsempfinden
   */
  public rpe: number = 0;
  /**
   * der Standard Konstruktor
   * @param httpRemoteObject das Remote Objekt, das auf eine echte Klasseninstanz gemapped werden muss
   */
  constructor(httpRemoteObject: TrainingEntry = null) {
    if (httpRemoteObject) {
      this.category = httpRemoteObject.category;
      this.rpe = httpRemoteObject.rpe;
      this.duration = httpRemoteObject.duration;
      this.date = new Date(httpRemoteObject.date);
      this.week = httpRemoteObject.week;
      this.year = httpRemoteObject.year;
      this.method = httpRemoteObject.method;
      this.newEntry = false;
    }
    else {
      this.date = new Date();
      this.newEntry = true;
    }
  }
  /**
   * Diese Funktion validiert die Eingabeklasse.
   * @returns true falls, die Klasse korrekt ist und false, falls sie Eingabefehler aufweist.
   */
  public valid(): boolean {
    if (this.date &&
      this.category &&
      this.method &&
      this.duration > 0 &&
      this.week > 0 &&
      this.year > 0 &&
      this.rpe > 0) {
      return true;
    }
    else {
      return false;
    }
  }
}