import { Component, OnInit, Host } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from '../_helper/messages';
import { LoginGuardServiceHistory } from '../_helper/guard';
import { UserConnector } from '../_service/users';
import { TechnicalUserEntry } from '../_model/user';
import { HttpErrorResponse } from '@angular/common/http';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private connector: UserConnector,
    private router: Router,
    private history: LoginGuardServiceHistory,
    private log: LogService) { }

  ngOnInit() {
  }
  /**
   * Diese Funktion verarbeitet das Facebook User login und authentifiziert den Benutzer im Backend (und stellt die Informationen
   * als Observeable zur Verfügung)
   */
  login() {
    this.connector.authenticateUser().subscribe(
      (user: TechnicalUserEntry) => {
        this.log.logSuccess("Anmeldung erfolgreich", "Der Benutzer \"" + user.name + "\" ist erfolgreich angemeldet");
        // header neu initialisieren, damit das Menü korrekt angezeigt wird
      },
      (error: HttpErrorResponse) => {
        this.log.logHttpError("Anmeldefehler", "Fehler bei der Anmeldung", error);
      },
      () => {
        // navigiere zum ursprünglichen Ziel
        if (this.history.origin){
          this.router.navigate([this.history.origin]);
        }
        else {
          // falls die Login Seite direkt gewählt wurde navigiere zur Startseite
          this.router.navigate(['']);
        }
      }
    );
  }
}
