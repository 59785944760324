import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphDrawer, ReportOptions } from '../interface';
import { ReportConnector } from 'src/app/_service/reports';
import { LogService } from 'src/app/_helper/messages';
import { HttpErrorResponse } from '@angular/common/http';
import { UIChart } from 'primeng/chart';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-basics',
  templateUrl: './basics.component.html',
  styleUrls: ['./basics.component.css']
})
export class BasicsComponent implements OnInit, GraphDrawer {
    /**
   * das Chart Objekt für Methoden
   */
  @ViewChild('chart') chart: UIChart;
  /**
   * die geladenen Reportdaten
   */
  baseData: any;
  /**
   * die Daten für die Anzeigegraphik
   */
  graphData: any;
  /**
   * die zur Auswahl stehenden Basiskategorien
   */
  basics: any;
  /**
   * die ausgewählte Ansicht
   */
  selectedBasic:SelectItem = {label:'Herzfrequenz', value:'heartrate'};

  constructor(private connector: ReportConnector, private message: LogService) { }

  ngOnInit() {
    // Farben für Kategorie Dognut setzen
    this.chart.options = ReportOptions.CHART_OPTIONS;
    //this.chart.options.legend.display = false;
    this.basics = [
      {label:'Herzfrequenz', value:'heartrate'},
      {label:'Gewicht', value:'weight'},
      {label:'Stimmung', value:'mood'},
      {label:'Schlaf', value:'sleep'},
      {label:'EWMA 7', value:'ewma7'},
      {label:'EWMA 28', value:'ewma28'}
  ];
  }

  drawGraph(startDate: Date, endDate: Date, user:number) {
     this.connector.loadBasics(startDate, endDate, user).subscribe(
      (data: any[]) => {
        this.baseData = data;
      },
      (error:HttpErrorResponse) => {
        this.message.logError("Ladefehler", "Die nötigen Daten konnten nicht geladen werden: " + error.message);
      },
      () => this.changeGraph())
  }
  /**
   * Diese Funktion ändert die Anzeige in der Graphik
   * @param event das zugehörige Event
   */
  changeGraph(){
    this.graphData = {
      labels: [],
      datasets: [
        {
          label: this.selectedBasic.label,
          lineTension: 0,
          borderColor: ReportOptions.PRIMARY_COLORS[1],
          backgroundColor: ReportOptions.PRIMARY_COLORS[2],
          borderWidth: 5,
          data: [],
          customNoTitle: true,
          customXOffset: 15,
          customYOffset: 50
        }
      ]
    };
    this.baseData.forEach((data:any) => {
      this.graphData.labels.push(moment(data.date).format("DD.MM.YYYY"));
      this.graphData.datasets[0].data.push(data[this.selectedBasic.value]);
    });
  }

}
