import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserConnector } from '../_service/users';
/**
 * Diese Klasse hält die ursprünglich annavigierte URL, damit sie nach dem Login aufgerufen werden kann.
 * Diese Klasse muss als erstes in der Datei stehen, da sie von der unteren verwendet wird.
 */
@Injectable()
export class LoginGuardServiceHistory{
  /**
   * the original URL
   */
  public origin:string;
  /**
   * der Konstruktor
   */
  constructor(){}
}
/**
 * Diese Klasse schützt die Routen gegen nicht eingeloggten Zugriff.
 */
@Injectable()
export class LoginGuardService implements CanActivate {
  /**
   * der Name der Login Route
   */
  private static LOGIN_ROUTE_NAME:string = "login";

  constructor(private router: Router, 
    private connector:UserConnector,
    private history:LoginGuardServiceHistory) {}

  canActivate(activatedRoute:ActivatedRouteSnapshot): boolean {
    if (!this.connector.getCurrentUser()) {
      // zur Login Seite navigieren, aber das nicht in der History speichern
      // die momentan aktive Route ist ein Parameter in canActivate laut https://github.com/angular/angular/issues/11732
      this.history.origin = activatedRoute.url.toString();
      this.router.navigate([LoginGuardService.LOGIN_ROUTE_NAME]);
      return false;
    }
    else {
      return true;
    }
  }

}