/**
 * Dies ist die Konfiguration für die Integrationstests mit dem PHP Backend.
 * Es wird über ng serve --configuration=integration aufgerufen (ist in der Datei angular.json konfiguriert)
 */
export const environment = {
  /**
   * der Environment Name
   */
  name: "Integration",
  /**
   * wahr, falls es sich um ein Produktions-Environment handelt (Angular Standardvariable)
   */
  production: false,
  /**
   * der Interceptor soll nichts machen
   */
  fake_backend: false,
  /**
   * die Backend root URL
   */
  backend_root: "/backend"
  //backend_root: "http://localhost:8080"
};
