import { Component, OnInit, ViewChild} from '@angular/core';
import { LoadComponent } from './load/load.component';
import { GraphDrawer, ReportOptions } from './interface';
import * as moment from 'moment';
import { TimelineComponent } from './timeline/timeline.component';
import { BasicsComponent } from './basics/basics.component';
import { AppComponent } from '../app.component';
import { SelectItem } from 'primeng/api';
import { UserConnector } from '../_service/users';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { LogService } from '../_helper/messages';
import { UserEntry } from '../_model/user';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  /**
   * die Kalender Locale
   */
  de:any = AppComponent.CALENDAR_LOCALE;
  /**
   * das Report Startdatum
   */
  startDate: Date = new Date();
  /**
   * das Report Enddatum
   */
  endDate: Date = new Date();
  /**
   * die Liste der Benutzer
   */
  users: SelectItem[];
  /**
   * der ausgewählte Benutzer
   */
  selectedUser;
  /**
   * die untergeordnete Komponente
   */
  @ViewChild(LoadComponent) load: GraphDrawer;
  @ViewChild(TimelineComponent) timeline: GraphDrawer;
  @ViewChild(BasicsComponent) basics: GraphDrawer;

  constructor(private connector:UserConnector, private messages:LogService) { 
  }

  ngOnInit() {
    // Tooltips registrieren
    ReportOptions.registerStaticTooltips();
    this.connector.loadUsers()
    .subscribe(
      (entries: UserEntry[]) => this.users = entries.map((entry:UserEntry) => {
        return {
          value: entry.id,
          label: entry.name
        }
      }),
      (error: HttpErrorResponse) =>
        this.messages.logHttpError("Lesefehler", "Fehler beim Lesen der Benutzer", error),
      () => this.selectedUser = this.connector.getCurrentUser().id
    );
  }
  /**
   * Diese Funktion zeigt einen Report auf der zugehörigen Kindkomponente (welche gerade aktiv ist)
   */
  showChildReport() {
      this.timeline.drawGraph(this.startDate, this.endDate, this.selectedUser);
      this.load.drawGraph(this.startDate, this.endDate, this.selectedUser);
      this.basics.drawGraph(this.startDate, this.endDate, this.selectedUser);
  }
  /**
   * Diese Funktion ändert das Startdatum in der Auswahl entsprechend der angegebenen Anzahl von Tagen
   * @param days die Anzahl der Tage
   */
  changeStartDate(days:number){
    this.startDate = moment().subtract(days, 'days').toDate();
  }
}