import { MessageService } from 'primeng/api';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { runInThisContext } from 'vm';

@Injectable()
export class LogService {

	constructor(private messageService: MessageService, private log:NGXLogger, private router:Router) { }
	/**
   * Diese Funktion loggt eine Erfolgsnachricht.
   * @param message die Nachricht
   * @param heading die Überschrift
   */
	public logSuccess(heading: string, message: string) {
		this.messageService.add({
			severity: 'success',
			summary: heading,
			detail: message
		})
	}


	/**
   * Diese Funktion loggt eine Info Nachricht.
   * @param message die Nachricht
   * @param heading die Überschrift
   */
	public logInfo(heading: string, message: string) {
		this.messageService.add({
			severity: 'info',
			summary: heading,
			detail: message
		})
	}
	/**
	 * Diese Funktion loggt eine Fehlermeldung.
	 * @param message die Nachricht
	 * @param heading die Überschrift
	 */
	public logError(heading: string, message: string) {
		this.messageService.add({
			severity: 'error',
			summary: heading,
			detail: message
		})
	}
	/**
	 * Diese Funktion gibt einen HTTP Fehler aus.
	 * @param heading die Überschrift
	 * @param message die Nachricht
	 * @param error das HTTP Fehlerobjekt
	 */
	public logHttpError(heading: string, message: string, error: HttpErrorResponse){
		this.log.error("Fehler in der Abarbeitung:" + error.status + "-" + error.statusText + ":" + error.message);
		this.messageService.add({
			severity: 'error',
			summary: heading,
			detail: message + "(Status:" + error.status + " - " + error.statusText + ")"
		})
		if (error.status == 403){
			this.router.navigate(['forbidden/' + error.statusText]);
		}
	}
}