import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {
  /**
   * die Fehlermeldung welche angezeigt werden soll
   */
  message:string = "";

  constructor(private route:ActivatedRoute) { }

  ngOnInit() {
    this.message = this.route.snapshot.params['message'];
  }

}
