import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UserEntry, TechnicalUserEntry } from '../_model/user';
import { AuthService, FacebookLoginProvider, SocialUser } from 'angularx-social-login';
import { from } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { FightingCategory } from '../_model/category';
import { environment } from '../../environments/environment';
import { SelectItem } from 'primeng/api';

/**
 * Diese Klasse stellt die Verbindung zum Backend für Benutzer dar.
 */
@Injectable()
export class UserConnector {
  constructor(private http: HttpClient, private authService: AuthService, private log: NGXLogger) {
    this.updateHeaders();
  }
  /**
   * ein Notification object, welches angibt, ob der Benutzer eingeloggt ist
   */
  public validCurrentUser:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * die ID im local storage für den momentan eingeloggten Benutzer
   */
  private static LOCAL_STORAGE_CURRENT_USER_ID:string = "current_user_fe";
  /**
   * der momentan eingeloggte Benutzer
   */
  private currentUser: TechnicalUserEntry;
  /**
   * die Http Header
   */
  private headers;

  /**
   * Diese Funktion retourniert den momentan eingeloggten Benutzer oder null
   */
  public getCurrentUser(): TechnicalUserEntry {
    // suche im Session storage nach einem Eintrag
    if ((!this.currentUser) && (sessionStorage.getItem(UserConnector.LOCAL_STORAGE_CURRENT_USER_ID))) {
      this.currentUser = new TechnicalUserEntry(JSON.parse(sessionStorage.getItem(UserConnector.LOCAL_STORAGE_CURRENT_USER_ID)));
      this.updateHeaders();
    }
    return this.currentUser;
  }

  /**
  * Diese Funktion setzt den momentanen Benutzer
  */
  public setCurrentUser(user: TechnicalUserEntry) {
    if (!user){
      //Benutzer löschen
      sessionStorage.clear()
    }
    else {
      // momentaner Benutzer im session storage ablegen
      sessionStorage.setItem(UserConnector.LOCAL_STORAGE_CURRENT_USER_ID, JSON.stringify(user));
    }
    // suche im Session storage nach einem Eintrag
    this.currentUser = user;
    this.updateHeaders();
  }

  /**
   * Diese Funktion retourniert die benötigten Authentifizerungs-Header.
   */
  public getAuthHeaders(): any {
    return this.headers;
  }
  /**
   * Diese Funktion setzt die Header
   */
  private updateHeaders() {
    // benachrichtige alle subscriber, dass es einen gültigen Benutzer gibt
    if (this.currentUser){
      this.validCurrentUser.next(true);
    }
    else {
      this.validCurrentUser.next(false);
    }
    this.headers = {
      // Authorization Header bleibt leider am Apache hängen und kann daher nicht verwendet werden
      "X-Authorization" : ((this.currentUser) ? "Bearer " + this.currentUser.idToken : "Bearer not-set"),
      "Accept": "application/json"
    }
  }

  /**
   * Diese Funktion retourniert die Liste der Benutzer.
   */
  public loadUsers(): Observable<UserEntry[]> {
    // muss auf Klasse gemapped werden, da sonst Date und die Funktion valid nicht funktionieren
    return this.http.get<UserEntry[]>(environment.backend_root + "/users", { headers: this.headers }).pipe(
      map<any[], UserEntry[]>((response: any[]) => {
        return response.map(data => new UserEntry(data));
      }));
  }
  /**
   * Diese Funktion authorisiert einen Benutzer und speichert die Benutzerdaten
   */
  public authenticateUser(): Observable<TechnicalUserEntry> {
    // Login via facebook
    return from(this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)).pipe(
      // authorisiere am Backend mit der Token ID
      switchMap((facebookUser: SocialUser) => {
        this.log.debug("Authentifizieren user am Backend \"" + facebookUser.name + "\"");
        // header einmal manuell setzen, nachher kommt er eh wieder vom Backend zurück
        return this.http.get<TechnicalUserEntry>(environment.backend_root + "/users/authenticate", {
          headers: {
            // Custom header, da der normale Authorization header vom Apache geschluckt wird
            "X-Authorization": "Bearer " + facebookUser.authToken,
            "Accept": "application/json"
          }
        })
      }))
      .pipe(
        // auf konkretes Objekt mappen, da sonst die Datumsfelder nicht initalisiert werden
        map<any, TechnicalUserEntry>((data) => {
          return new TechnicalUserEntry(data)
        }),
        tap((user: TechnicalUserEntry) => {
          this.log.debug("Benutzer im lokale Storage speichern" + JSON.stringify(user));
          this.setCurrentUser(user);
        }
      ));
  }
  /**
   * Diese Funktion loggt den Benutzer aus
   */
  public logoutUser() {
    this.log.info("Benutzer ausloggen");
    this.setCurrentUser(null);
    this.authService.signOut();
  }
  /**
   * Diese Funktion speichert einen neuen Benutzer oder aktualisiert einen vorhandenen.
   * @param entry der Benutzer
   */
  public storeUser(entry: UserEntry): Observable<HttpResponse<any>> {
    return this.http.post(environment.backend_root + "/users", entry, { headers: this.headers, observe: 'response' });
  }
  /**
   * Diese Funktion legt einen Benutzer neu an
   * @param entry der Benutzer
   */
  public updateUser(entry: UserEntry): Observable<TechnicalUserEntry> {
    return this.http.put<TechnicalUserEntry>(environment.backend_root + '/users', entry, { headers: this.headers });
  }
  /**
   * Diese Funktion lädt die Fighter Kategorien
   */
  public loadFightingCategoryData(): Observable<FightingCategory[]> {
    //return this.http.get<FightingCategory[]>("assets/categories/" + this.currentUser.sport + "/fightingCategories.json");
    return this.http.get<FightingCategory[]>("assets/categories/jiujitsu/fightingCategories.json");
  }
  /**
   * Diese Funktion lädt Gruppen Kategorien
   */
  public loadGroupCategoryData(): Observable<SelectItem[]> {
    return this.http.get<FightingCategory[]>("assets/categories/" + this.currentUser.sport + "/groups.json");
  }
}