import { Component, OnInit } from '@angular/core';
import { LogService } from '../_helper/messages';
import { Table } from 'primeng/table';
import { UserEntry } from '../_model/user';
import { UserConnector } from '../_service/users';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  /**
   * die Spalten der Tabelle
   */
  userCols = [
    { field: 'name', header: 'Name', width: 300 },
    { field: 'email', header: 'E-Mail', width: 300 },
    { field: 'trainer', header: 'Trainer', width: 120 },
    { field: 'deactivated', header: 'Inaktiv', width: 120 },
    { field: 'director', header: 'Direktor', width: 120 },
    { field: 'sportgroup', header: 'Gruppe', width: 180 }
  ];
  /**
   * die möglichen Gruppen
   */
  groups: SelectItem[];
  /**
   * die Benutzer einer Gruppe
   */
  users: UserEntry[];
  /**
   * Backup für die ursprünglichen Zellen, damit ein Cancel möglich ist
   */
  backupUsers: { [id: string]: UserEntry; } = {};

  constructor(private connector: UserConnector, private log: LogService) { }

  ngOnInit() {
    this.loadUsers();
    this.connector.loadGroupCategoryData().subscribe(
      (entries: SelectItem[]) => this.groups = entries,
      (error: HttpErrorResponse) =>
        this.log.logHttpError("Lesefehler", "Fehler beim Lesen der Sportgruppen", error)
    );
  }
  /**
   * Diese Funktion lädt alle Benutzer einer Gruppe
   */
  loadUsers() {
    this.connector.loadUsers()
      .subscribe(
        (entries: UserEntry[]) => this.users = entries,
        (error: HttpErrorResponse) =>
          this.log.logHttpError("Lesefehler", "Fehler beim Lesen der Benutzer", error)
      );
  }
  /**
   * Diese Funktion speichert den Benutzer
   * @param user der geänderte Benutzer
   */
  storeUserEntry(user: UserEntry) {
    let userObject: UserEntry = new UserEntry(user);
    // Benutzerobjekt überprüfen
    if (!userObject.validBase()) {
      this.log.logError(
        'Eingabefehler',
        'Nicht alle Felder sind ausgefüllt. Benutzer wird nicht gespeichert.'
      );
    }
    else {
      if (userObject.id === UserEntry.KEY_NOT_SET) {
        // Neuen Benutzer speichern
        this.connector.storeUser(userObject).subscribe(
          (response: HttpResponse<any>) =>
            this.log.logSuccess("Erfolg", "Der neuer Benutzer wurde erfolgreich gespeichert."),
          (error: HttpErrorResponse) => {
            this.log.logHttpError("Fehler", "Der Benutzer konnte nicht gespeichert werden", error)
            this.loadUsers();
          },
          () => {
            this.loadUsers();
          }
        )
      }
      else {
        // Benutzer aktualisieren (key ist vorhanden)
        this.connector.updateUser(userObject).subscribe(
          (entry) =>
            this.log.logSuccess("Erfolg", "Der neuer Benutzer wurde erfolgreich gespeichert."),
          (error: HttpErrorResponse) => {
            this.log.logHttpError("Fehler", "Der Benutzer konnte nicht gespeichert werden", error)
            this.loadUsers();
          },
          () => {
            this.loadUsers();
          }
        );
      }
    }
  }

  /**
   * Diese Funktion erstellt einen neuen Benutzer
   */
  newUserEntry(table: Table) {
    let newUser: UserEntry = new UserEntry();
    newUser.sportgroup = this.connector.getCurrentUser().sportgroup;
    this.users.push(newUser);
    table.editingRowKeys[UserEntry.KEY_NOT_SET] = true;
  }
}
