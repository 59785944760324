import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { DailyComponent } from './daily/daily.component';
import { TrainingComponent } from './training/training.component';
import { LogService } from '../_helper/messages';
import * as moment from 'moment';
import { TrainingConnector } from '../_service/trainings';
import { DailyConnector } from '../_service/daily';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.css']
})

export class CaptureComponent implements OnInit {
  /**
   * das aktive Tab
   */
  activeTab:number;
  /**
   * das angefragte Datum (vorallem für Testzwecke)
   */
  requestedDate: Date;

  constructor(private route:ActivatedRoute, 
    private log: NGXLogger, 
    private message: LogService, 
    private connector: DailyConnector) { }
  /**
   * die Daily-Kindkomponente
   */
  @ViewChild(DailyComponent) daily: DailyComponent;
  /**
   * die Trainings-Kindkomponente
   */
  @ViewChild(TrainingComponent) training: TrainingComponent;

  ngOnInit() {
    this.activeTab = this.route.snapshot.data['tab'];
    let paramDate:string = this.route.snapshot.params['date'];
    if (paramDate){
      // T00:00:00Z ergibt ein Problem, da es anscheinend einen Zeitzone Bug gibt
      this.requestedDate = new Date(paramDate + 'T00:00:00Z');
      this.log.debug("Aufruf mit Datum [" + this.requestedDate + "]");
    }
    else {
      this.requestedDate = new Date();
    }
    this.loadData();
  }
  /**
   * Diese Funktion lädt die Daten in den Tabs, wenn sich das Datum im Auswahlfeld ändert.
   */
  loadData(){
    if ((moment(this.requestedDate).isBefore(moment(new Date()).subtract(14, 'days')))
     || (moment(this.requestedDate).isAfter(moment(new Date())))
    ){
      this.message.logError(
        'Eingabefehler',
        'Das Datum kann maximal 14 Tage in der Vergangenheit liegen und darf nicht in der Zukunft liegen.'
      );
      this.requestedDate = new Date();
      return;
    }
    this.daily.init(this.requestedDate);
    this.training.init(this.requestedDate);
  }
  /**
   * Diese Funktion speichert die Daten im Backend
   */
  storeData(){
    if (!this.daily.validateData()){
      this.message.logError(
        'Eingabefehler',
        'Nicht alle Felder im Tagesdatensatz ausgefüllt. Datensatz wird nicht gespeichert.'
      );
      return;
    }
    if (!this.training.validateData()){
      this.message.logError(
        'Eingabefehler',
        'Nicht alle Felder im Trainingsdatensatz ausgefüllt. Datensatz wird nicht gespeichert.'
      );
      return;
    }
    this.daily.storeData();
    this.training.storeData();
    // update EWMA
    this.connector.updateEWMA(this.requestedDate).subscribe(
      (response: HttpResponse<any>) =>
        this.message.logSuccess("Erfolg", "Das EWMA wurden erfolgreich aktualisiert."),
      (error: HttpErrorResponse) => {
        this.message.logHttpError(
          'Datenbankfehler',
          'Die EWMA Daten konnten nicht aktualisiert werden',
          error
        );
      });
  }
}
