import { TrainingEntry } from "../_model/entries";
import { Observable} from 'rxjs';
import { UserConnector } from './users';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TrainingCategory} from '../_model/category';
import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
/**
 * Diese Klasse speichert und aktualisiert die Trainingseinträge.
 */
@Injectable()
export class TrainingConnector {
  constructor(private http: HttpClient, private connector: UserConnector) {
  }
  /**
   * 
   * Diese Funktion holt Trainingsdaten zu einem Datum
   * @param date das Datum
   */
  public loadTrainings(date: Date): Observable<TrainingEntry[]> {
    return this.http.get<TrainingEntry[]>(environment.backend_root +'/trainings/' + formatDate(date,"yyyy-MM-dd","en"), { headers: this.connector.getAuthHeaders()})
    // muss auf Klasse gemapped werden, da sonst Date und die Funktion valid nicht funktionieren
    .pipe(
      map<any[],TrainingEntry[]>((response:any[]) => {             
          return response.map(data => new TrainingEntry(data));
      }));
  }
  /**
   * Diese Funktion lädt die Kategorien aus einer Datei.
   */
   public loadTrainingCategoryData(): Observable<TrainingCategory[]> {
    return this.http.get<TrainingCategory[]>("assets/categories/" + this.connector.getCurrentUser().sport + "/trainingCategories.json");
  }
  /**
   * Diese Funktion speichert einen Trainingseintrag in der Datenbank
   * @param entry der Trainingseintrag
   */
  public storeTraining(entry: TrainingEntry):Observable<HttpResponse<any>> {
    return this.http.post<TrainingEntry>(environment.backend_root + '/trainings', entry, { headers: this.connector.getAuthHeaders(), observe: 'response' });
  }
  /**
   * Diese Funktion aktualisiert einen Trainingseintrag.
   * @param entry der Trainingseintrag
   */
  public updateTraining(entry: TrainingEntry):Observable<HttpResponse<any>> {
    return this.http.put<TrainingEntry>(environment.backend_root + '/trainings', entry, { headers: this.connector.getAuthHeaders(), observe: 'response' });
  }
}