import { Component, OnInit, Inject, Host } from '@angular/core';
import { DailyEntry } from '../../_model/entries';
import * as moment from 'moment';
import { LogService } from 'src/app/_helper/messages';
import { DailyConnector } from 'src/app/_service/daily';
import { Observable } from 'rxjs';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { CaptureChildComponent } from 'src/app/report/interface';

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.css']
})
/**
 * Diese Komponente beschreibt die tägliche Basis in der Erfassung
 */
export class DailyComponent implements OnInit, CaptureChildComponent {
  /**
   * die Wort für die Stimmung
   */
  private static MOOD_WORDS: String[] = [
    "sehr schlecht",
    "schlecht",
    "ok",
    "gut",
    "sehr gut",
    "ausgezeichnet"
  ];
  /**
   * der Zeitpunkt der letzten Erfassung
   */
  lastDataset: String;
  /**
   * der Wortlaut für den Stimmungsbalken
   */
  moodWording: String;
  /**
   * der Datensatz, welcher die Tagestrainingsdaten enthält
   */
  daily: DailyEntry = new DailyEntry();
  /**
   * der Konstruktor
   */
  constructor(private connector: DailyConnector, private message: LogService, private log: NGXLogger) { }

  ngOnInit() {}

  init(date:Date){
    // Datum
    this.daily.date = new Date(date);
    this.loadDailyData();
  }

  validateData():boolean{
    // Wochennummer setzen
    this.daily.week = moment(this.daily.date).isoWeek();
    // Datums Bug, Zeitzoneshift rückrechnen, damit wieder das korrekte Datum herauskommt
    this.daily.date = moment(this.daily.date).add(-this.daily.date.getTimezoneOffset(), 'minutes').toDate();
    return this.daily.valid();
  }

  storeData() {
    // Daten speichern
    if (this.daily.new) {
      // Neuen Benutzer speichern
      this.executeRequest(this.connector.storeDaily(this.daily), this.daily.date);
    }
    else {
      // Benutzer aktualisieren (key ist vorhanden)
      this.executeRequest(this.connector.updateDaily(this.daily), this.daily.date);
    }
  }
  /**
  * Diese Funktion führt einen speichern oder aktualisieren Request aus
  * @param request der Request
  * @param selectedTrainingTime die ausgewählte Trainingszeit vor dem Request
  */
  private executeRequest(request: Observable<HttpResponse<any>>, selectedTrainingTime: Date) {
    request.subscribe(
      (response: HttpResponse<any>) =>
        this.message.logSuccess("Erfolg", "Das Tagesdaten wurden erfolgreich gespeichert."),
      (error: HttpErrorResponse) => {
        this.message.logHttpError("Fehler", "Die Tagesdatum konnte nicht gespeichert werden", error);
      },
      () => {
        this.loadDailyData(false);
      }
    );
  }

  /**
   * Diese Funktion ändert den Wortlaut für die Stimmung entsprechend
   */
  changeMoodWording() {
    this.moodWording = DailyComponent.MOOD_WORDS[this.daily.mood - 1];
  }
  /**
   * Diese Funktion lädt das letzte eingegebene Datenset auf Basis des selektierten Datums
   */
  loadDailyData(notifyUser:boolean=true) {
    this.connector.loadDaily(this.daily.date)
      .subscribe((entry: DailyEntry) => {
        if (!entry.new) {
          this.daily = entry;
          if (notifyUser){
            this.message.logInfo(
              'Datensatz vorhanden',
              'Ein Datensatz ist für den ausgewählten Tag schon vorhanden'
            );
          }
        }
        else {
          this.daily = new DailyEntry(null, this.daily.date);
        }
      },
      (error: Error) => {
        this.message.logError(
          'Datenbankfehler',
          'Die Tagesdaten konnten nicht geholt werden (Fehler:' + error.message + ')'
        );
      });
  }
}
