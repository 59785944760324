import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CaptureComponent } from './capture/capture.component';

import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { RatingModule } from 'primeng/rating';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { FieldsetModule } from 'primeng/fieldset';
import { KeyFilterModule } from 'primeng/keyfilter';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import {ToastModule} from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { TrainingComponent } from './capture/training/training.component';
import { TooltipModule } from 'primeng/tooltip';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseComponent } from './base/base.component';
import { ZeroNumberPipe } from './_helper/zeronumber.pipe';
import { AdminComponent } from './admin/admin.component';
import { LogService } from './_helper/messages';
import { MessageService } from 'primeng/api';
import { HeaderComponent } from './header/header.component';
import { ToolbarModule } from 'primeng/toolbar';
import { CheckboxModule } from 'primeng/checkbox';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { FacebookLoginProvider} from "angularx-social-login";
import { LoginComponent } from './login/login.component';
import { LoginGuardService, LoginGuardServiceHistory } from './_helper/guard';
import { fakeBackendProvider, noopProvider } from './_helper/fake-backend';
import { UserConnector } from './_service/users';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TrainingConnector } from './_service/trainings';
import { ReportComponent } from './report/report.component';
import { PanelModule } from 'primeng/panel';
import { LoadComponent } from './report/load/load.component';

import { ChartModule } from 'primeng/chart';
import { DailyConnector } from './_service/daily';
import { ReportConnector } from './_service/reports';
import { TimelineComponent } from './report/timeline/timeline.component';
import { BasicsComponent } from './report/basics/basics.component';

import {SelectButtonModule} from 'primeng/selectbutton';
import { ImpressumComponent } from './header/impressum/impressum.component';

import { CookieService } from 'ngx-cookie-service';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { environment } from '../environments/environment';
import { DailyComponent } from './capture/daily/daily.component';
import { ProvidersFeature } from '@angular/core/src/render3';

let config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("369679276966134")
  }
]);

export function provideConfig() {
  return config;
}

//TODO:  Export von Rohdaten

@NgModule({
  declarations: [
    AppComponent,
    CaptureComponent,
    DailyComponent,
    TrainingComponent,
    BaseComponent,
    ZeroNumberPipe,
    AdminComponent,
    HeaderComponent,
    LoginComponent,
    ReportComponent,
    LoadComponent,
    TimelineComponent,
    BasicsComponent,
    ImpressumComponent,
    ForbiddenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TabViewModule,
    FormsModule,
    CalendarModule,
    RatingModule,
    BrowserAnimationsModule,
    DropdownModule,
    InputTextModule,
    FieldsetModule,
    KeyFilterModule,
    TableModule,
    MenuModule,
    ToastModule,
    TooltipModule,
    HttpClientModule,
    MessageModule,
    ToolbarModule,
    CheckboxModule,
    SocialLoginModule,
    LoggerModule.forRoot({level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR}),
    PanelModule,
    ChartModule,
    SelectButtonModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    UserConnector,
    TrainingConnector,
    DailyConnector,
    ReportConnector,
    LogService,
    MessageService,
    LoginGuardService,
    LoginGuardServiceHistory,
    CookieService,
    (environment.fake_backend) ? fakeBackendProvider : noopProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
