/**
 * Diese Klassen entspricht einen einfachen Benutzereintrag
 */
export class UserEntry {
    /**
     * Standardwert, falls der Schlüssel nicht gesetzt ist
     */
    public static KEY_NOT_SET:string = "<not set>";
    /**
     * der eindeutige Eintragsschlüssel
     */
    public id:string = UserEntry.KEY_NOT_SET;
    /**
     * der Name
     */
    public name: string;
    /**
     * die Facebook E-Mail Adresse
     */
    public email: string;
    /**
     * die zugehörige Gruppe
     */
    public sportgroup:string;
    /**
     * die zugehörige Sportart
     */
    public sport:string;
    /**
     * wahr, falls der Benutzer deaktiviert ist
     */
    public deactivated: boolean = false;
    /**
     * wahr, wenn der Benutzer ein Trainer ist
     */
    public trainer: boolean =false;
    /**
     * wahr, wenn der Benutzer ein Sportdirektor ist
     */
    public director: boolean = false;
        /**
     * das Geschlecht
     */
    public gender: string;
    /**
     * die Altersklasse
     */
    public ageClass: string;
    /**
     * die Gewichtsklasse
     */
    public weightClass: string;
    /**
     * die Größe
     */
    public height: number = 0;
    /**
     * das Gewicht
     */
    public weight: number = 0;
    /**
     * der BMI Index
     */
    public bmi: number = 0;
    /**
     * die Herzfrequenz
     */
    public heartrate: number = 0;
    /**
     * der Geburtstag
     */
    public birthday: Date;
    /**
     * das Alter
     */
    public age: number = 0;
  
    constructor(httpRemoteObject: UserEntry = null) {
      if (httpRemoteObject) {
        this.id = httpRemoteObject.id;
        this.name = httpRemoteObject.name;
        this.sport = httpRemoteObject.sport;
        this.sportgroup = httpRemoteObject.sportgroup;
        this.email = httpRemoteObject.email;
        this.deactivated = Boolean(httpRemoteObject.deactivated as unknown == "1");
        this.trainer = Boolean(httpRemoteObject.trainer as unknown == "1");
        this.director = Boolean(httpRemoteObject.director as unknown == "1");
        this.gender = httpRemoteObject.gender;
        this.ageClass = httpRemoteObject.ageClass;
        this.weightClass = httpRemoteObject.weightClass;
        this.weight = httpRemoteObject.weight;
        this.height = httpRemoteObject.height;
        this.age = httpRemoteObject.age;
        this.heartrate = httpRemoteObject.heartrate
        this.birthday = new Date(httpRemoteObject.birthday);
        this.bmi = httpRemoteObject.bmi;
      }
    }
    /**
     * Diese Funktion validiert die Eingabeklasse.
     * @returns true falls, die Klasse korrekt ist und false, falls sie Eingabefehler aufweist.
     */
    public validBase(): boolean {
      if (this.name &&
        this.email) {
        return true;
      }
      else {
        return false;
      }
    }
    /**
     * Diese Funktion validiert die erweiterten Eingabedaten
     */
    public validExtended():boolean{
      if (this.gender &&
        this.ageClass &&
        this.weightClass &&
        this.weight > 0 &&
        this.height > 0 &&
        this.age > 0 &&
        this.heartrate > 0 &&
        this.bmi) {
        return true;
      }
      else {
        return false;
      } 
    }

  }
  /**
   * Diese Klasse ist ein technischer Benutzer. Diese Klasse wird nur vom Backend befüllt.
   */
  export class TechnicalUserEntry extends UserEntry{
    /**
     * das zugehörige ID token
     */
    public idToken:string;

    constructor(httpRemoteObject: TechnicalUserEntry = null) {
      super(httpRemoteObject);
      if (httpRemoteObject){
        this.idToken = httpRemoteObject.idToken;
      }
    }
  }