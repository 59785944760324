import { Component, OnInit } from '@angular/core';
import { FightingCategory, FightingClass } from 'src/app/_model/category';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment';
import { LogService } from 'src/app/_helper/messages';
import { UserEntry, TechnicalUserEntry } from 'src/app/_model/user';
import { UserConnector } from 'src/app/_service/users';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {
  /**
   * die Kategorie der BMI Maßzahl
   */
  bmiWording: string = "";
  /**
   * die Wettkampfkategorien und Klassen
   */
  categories: FightingCategory[] = [];
  /**
   * die Gewichtsklasse
   */
  weightClasses: SelectItem[] = [];
  /**
   * die Altersklasse
   */
  ageClasses: FightingClass[] = [];
  /**
   * der zugehörige Basiseintrag (wird in NgOnInit überschrieben, muss aber hier stehen, sonst gibt es Fehler)
   */
  base: UserEntry = new UserEntry();
  /**
   * die möglichen Wörter für den BMI
   */
  private static BMI_WORDS: BMI[] = [
    {
      limit: 50,
      wording: "Adipositas"
    },
    {
      limit: 30,
      wording: "Übergewicht"
    },
    {
      limit: 25,
      wording: "Normalgewicht"
    },
    {
      limit: 18.5,
      wording: "Untergewicht"
    }
  ];

  constructor(private connector: UserConnector, private log: LogService) { }

  ngOnInit() {
    // die Liste der Kategorien speichern
    this.connector.loadFightingCategoryData().subscribe((categories: FightingCategory[]) => this.categories = categories,
      (error: Error) => {
        this.log.logError(
          'Datenbankfehler',
          'Die Kategoriedaten konnten nicht geholt werden (Fehler:' + error.message + ')'
        );
      },
      () => {
        this.base = this.connector.getCurrentUser();
        this.loadAgeClasses();
        this.loadWeightClasses();
        this.calculateBMI();
      });

  }
  /**
   * Diese Funktion lädt die Altersklassen in die zugehörige Variable für die Anzeige im Dropdown
   */
  loadAgeClasses() {
    if (this.base.gender) {
      this.ageClasses = this.categories.filter((category: FightingCategory) => { return category.value === this.base.gender })[0].classes;
    }
    this.weightClasses = []
  }
  /**
   * Diese Funktion lädt die Gewichtsklassen in die zugehörige Variable für die Anzeige im Dropdown
   */
  loadWeightClasses() {
    if (this.base.ageClass) {
      this.weightClasses = this.ageClasses.filter((category: FightingClass) => { return category.value === this.base.ageClass })[0].weights;
    }
  }
  /**
   * Diese Funktion rechnet den BMI neu aus
   */
  calculateBMI() {
    let BreakException = {};
    if ((this.base.weight > 0) && (this.base.height > 0)) {
      this.base.bmi = (this.base.weight / ((this.base.height) * (this.base.height)));
      this.base.bmi = Math.round(this.base.bmi * 100) / 100;
      try {
        BaseComponent.BMI_WORDS.forEach((word: BMI) => {
          if (this.base.bmi < word.limit) {
            this.bmiWording = word.wording;
          }
          else {
            throw BreakException;
          }
        });
      }
      catch (e) {
        if (e !== BreakException) throw e;
      }
    }
  }
  /**
   * Diese Funktion errechnet das Alter.
   */
  calculateAge() {
    this.base.age = moment().diff(this.base.birthday, 'years');
  }
  /**
   * Diese Funktion speichert die Basisdaten.
   */
  storeBase() {
    // Datums Bug, Zeitzoneshift rückrechnen, damit wieder das korrekte Datum herauskommt
    this.base.birthday = moment(this.base.birthday).add(-this.base.birthday.getTimezoneOffset(), 'minutes').toDate();
    if (!this.base.validExtended()) {
      this.log.logError(
        'Eingabefehler',
        'Nicht alle Felder sind ausgefüllt. Datensatz wird nicht gespeichert.'
      );
    }
    else {
      // Daten speichern
      this.connector.updateUser(this.base).subscribe(
        (response: TechnicalUserEntry) => {
          this.log.logSuccess("Erfolg", "Die Basisdaten wurden erfolgreich gespeichert.");
          this.connector.setCurrentUser(new TechnicalUserEntry(response));
        },
        (error: HttpErrorResponse) => {
          this.log.logHttpError("Fehler", "Die Basisdaten konnte nicht gespeichert werden", error);
        },
        () => {
          this.base = this.connector.getCurrentUser();
          this.loadAgeClasses();
          this.loadWeightClasses();
          this.calculateBMI();
        }
      );
    }
  }
}
/**
 * ein BMI Limit und Wert Paar
 */
interface BMI {
  limit: number;
  wording: string;
}