import { Component } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  /**
   * das gesetzte Environment
   */
  env = environment;

  public static CALENDAR_LOCALE:any;

  constructor(){
    AppComponent.CALENDAR_LOCALE = {
      firstDayOfWeek: 1,
      dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
      dayNamesShort: ["So", "Mo", "Die", "Mi", "Do", "Fr", "Sa"],
      dayNamesMin: ["So", "Mo", "Die", "Mi", "Do", "Fr", "Sa"],
      monthNames: ["Jänner", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
      today: 'Heute',
      clear: 'Löschen'
    };
  }
}

