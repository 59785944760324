import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CaptureComponent } from './capture/capture.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { LoginGuardService } from './_helper/guard';
import { ReportComponent } from './report/report.component';
import { ImpressumComponent } from './header/impressum/impressum.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { BaseComponent } from './base/base.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'impressum',
    component: ImpressumComponent
  }, 
  {
    path: 'forbidden/:message',
    component: ForbiddenComponent
  }, 
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [LoginGuardService],
  },
  {
    path: 'training',
    component: CaptureComponent,
    canActivate: [LoginGuardService],
    data: {
      tab: "1"
    }
  },
  {
    path: 'training/:date',
    component: CaptureComponent,
    canActivate: [LoginGuardService],
    data: {
      tab: "1"
    }
  },
  {
    path: 'base',
    component: BaseComponent,
    canActivate: [LoginGuardService]
  },
  {
    path: 'base/:date',
    component: CaptureComponent,
    canActivate: [LoginGuardService],
    data: {
      tab: "0"
    }
  },
  {
    path: 'daily',
    component: CaptureComponent,
    canActivate: [LoginGuardService],
    data: {
      tab: "2"
    }
  },
  {
    path: 'daily/:date',
    component: CaptureComponent,
    canActivate: [LoginGuardService],
    data: {
      tab: "2"
    }
  },
  {
    path: 'capture',
    redirectTo: '/training',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/training',
    pathMatch: 'full'
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [LoginGuardService]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { enableTracing: false }
  )]
})
export class AppRoutingModule { }
